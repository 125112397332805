import dmshttp from "@/utils/dmsrequest";

//库存流水
export function getStock(data) {
    return dmshttp({
        // findAllInventoryLog
        url: "findGicAllInventoryLog",
        data
    });
}
export function exportStock(data) {
    return dmshttp({
        url: "exportGicAllInventoryLog&type=1",
        data,
        responseType: 'blob'
    });
}