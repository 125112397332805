import { getStock,exportStock } from "./api.js";
import navList from "../navList";
import Util from "@/utils/utils";

export default {
  data() {
    return {
      expLoading:false,
      pageLoadFlag: false,
      navList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/myStockNew",
          name: "myStock",
          title: "库存流水"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: "",
        sortOrder: ""
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      scroll: {
        x: "2600"
        // y: 400
      },
      columns: [
        {
          label: "产品型号",
          prop: "materialmodel",
          ellipsis: true,
          key: "materialmodel",
          // sortable: 'custom',
          // sortName: "p.model",
          width: 150
        },
        {
          label: "颜色",
          prop: "color",
          ellipsis: true,
          key: "color",
          width: 150
        },
        {
          label: "物料组",
          prop: "materialgroupname",
          ellipsis: true,
          key: "materialgroupname",
          width: 150
        },
        {
          label: "销售组织",
          prop: "orgname",
          ellipsis: true,
          key: "orgName",
          width: 150
        },
        {
          label: "仓库名称",
          prop: "warehousename",
          ellipsis: true,
          key: "warehousename",
          width: 180
        },
        {
          label: "定价组",
          prop: "pricegroupname",
          key: "pricegroupname",
          width: 150
        },
        {
          label: "仓库编码",
          prop: "warehousenum",
          ellipsis: true,
          key: "warehousenum",
          width: 180
        },
        {
          label: "质量等级",
          prop: "qualitylvName",
          ellipsis: true,
          key: "qualitylvName",
          width: 180
        },
        {
          label: "库存类型",
          prop: "invtypename",
          ellipsis: true,
          key: "invtypename",
          width: 180
        },
        {
          label: "库存状态",
          prop: "invstatusname",
          ellipsis: true,
          key: "invstatusname",
          width: 180
        },
        {
          label: "库存事务",
          prop: "invschemename",
          ellipsis: true,
          key: "invschemename",
          width: 180
        },
        {
          label: "数量",
          prop: "baseqty",
          ellipsis: true,
          key: "baseqty",
          width: 180
        },
        {
          label: "单位",
          prop: "baseunitname",
          ellipsis: true,
          key: "baseunitname",
          width: 180
        },
        {
          label: "业务类型",
          prop: "biztypename",
          ellipsis: true,
          key: "biztypename",
          width: 180
        },
        {
          label: "业务单据",
          prop: "srcbillnumber",
          ellipsis: true,
          key: "srcbillnumber",
          width: 180
        },
        {
          label: "单据类型",
          prop: "billtype",
          ellipsis: true,
          key: "billtype",
          width: 180
        },
        // {
        //   label: "业务日期",
        //   prop: "biztime",
        //   ellipsis: true,
        //   key: "biztime",
        //   width: 180
        // },
        {
          label: "出库单据",
          prop: "srcsysbillnumber",
          ellipsis: true,
          key: "srcsysbillnumber",
          width: 180
        },
        // {
        //   label: "单据时间",
        //   prop: "biztime",
        //   ellipsis: true,
        //   key: "biztime",
        //   width: 180
        // },
        {
          label: "货主",
          prop: "ownername",
          ellipsis: true,
          key: "ownername",
          width: 180
        },
        {
          label: "货主类型",
          prop: "ownertypeName",
          ellipsis: true,
          key: "ownertypeName",
          width: 180
        },
        {
          label: "物料编码",
          prop: "materialnumber",
          ellipsis: true,
          key: "materialnumber",
          width: 180
        },
        {
          label: "入库时采购价",
          prop: "price",
          ellipsis: true,
          key: "price",
          width: 180
        },
        {
          label: "补差类型",
          prop: "priceprottype",
          ellipsis: true,
          key: "priceprottype",
          width: 180
        },

        {
          label: "单据时间",
          prop: "auditTime",
          ellipsis: true,
          key: "auditTime",
          width: 180
        },
        {
          label: "产品线",
          prop: "prodline",
          ellipsis: true,
          key: "prodline",
          width: 180
        },
        {
          label: "定价组",
          prop: "pricegroup",
          ellipsis: true,
          key: "pricegroup",
          width: 180
        },

        // {
        //   label: "单据编号",
        //   prop: "billno",
        //   ellipsis: true,
        //   key: "billno",
        //   width: 180
        // },

      ],
      data: [],
      terms: {},
      showLoading:false
    };
  },
  created() {
    this.navList = navList.nav1;
  },
  methods: {
    searchFun(arg) {
      this.terms = arg[1];
      this.pager.pageNo = 1;
      this.getData();
    },
    //排序change事件
    onTableChange(sorter) {
      const sortName = this.columns.filter(it=>it.prop==sorter.prop)
      if(sortName.length<1){
        return
      }
      if (sorter && sorter.order) {
        this.pager.sortOrder =
            sorter.order == "ascending"
                ? "asc"
                : sorter.order == "descending"
                ? "desc"
                : "";
        this.pager.sortName = sortName[0].sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData();
    },
    exportFile(){
      this.expLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          supplierName: "",
          orgName: "",
          invStatusId: "",
          gicWarehouseType: "",
          stvNum: "",
          invStatusType: "",
          productName: "",
          operator: "",
          transactionType: "",
          colour: "",
          productCode: "",
          warehouseId: "",
          model: "",
          dataSource: "",
          startDate: "",
          endDate: "",
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        }
      };
      exportStock(data).then(res => {
        Util.blobToJson(res.data).then(content=>{
          if(content&&content.msg=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          Util.downloadFile(res.data,'收发明细.xls')
        }).finally(()=>{
          this.expLoading = false;
        })
      }).catch(err=>{
        this.expLoading = false;
      });
    },
    getData() {
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          supplierName: "",
          orgName: "",
          invStatusId: "",
          gicWarehouseType: "",
          stvNum: "",
          invStatusType: "",
          productName: "",
          operator: "",
          transactionType: "",
          colour: "",
          productCode: "",
          warehouseId: "",
          model: "",
          dataSource: "",
          startDate: "",
          endDate: "",
          ...this.terms
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        }
      };
      this.showLoading = true
      getStock(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        if(data.code !== '1') {
          this.data = data.data || [];
          this.showLoading = false
          this.pager.count = data.page.totalCount;
        }else {
          this.data = []
          this.$message.warning(data.msg)
          this.showLoading = false
          this.pager.count = 0
        }
      }).catch(error=>{
        this.showLoading = false
      });
    }
  }
};
