export default {
  nav1: [
    {
      title: "我的库存",
      path: "/stockCenter/myStock",
      active: false
    },
    {
      title: "库存快照",
      path: "/stockCenter/inventorySnapshot",
      active: false
    },
    {
      title: "库存流水",
      path: "/stockCenter/inventoryLog",
      active: false
    },
    {
      title: "我的超期库存",
      path: "/stockCenter/exceedStock",
      active: false
    },
    {
      title: "分销商库存",
      path: "/stockCenter/distributorStock",
      active: false
    }
  ],
  nav2: [
    {
      title: "我的库存",
      path: "/stockCenter/myStock"
    },
    {
      title: "库存流水",
      path: "/stockCenter/inventoryLog",
      active: false
    },
    {
      title: "超期库申请记录",
      path: "/stockCenter/shareLog",
      active: true
    },
    {
      title: "分销商库存",
      path: "/stockCenter/distributorStock"
    }
  ]
};
